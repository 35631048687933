<template>
	<!--新增发起退租-->
	<mainBox mClass='fqBox'>
		<div class="addHtml">
			<div class="infoFrom" ref="infoFrom">
				<TForm class='basisBox' :autoCheck='true'  ref="htmlFrom" :model="pageParams"  :formlist="formlist" label-width="158px" labelPosition='left'>
					<template slot="contractInfo" slot-scope="scope" ><!--合同信息-->
							<TForm  ref="htmlFrom4" class="showlable" :model="contractObjs"  :formlist="contractForm" label-width="128px" ></TForm>
					</template>
					<template slot="deliver" slot-scope="scope" >
						<div class="labelTxt">撤场交付标准</div>
							<el-radio @change='changeDelivetype' v-model="pageParams[scope.current.keys]"  v-for='(ck,dex3) in scope.current.options' :key='dex3' :label="ck.code" border >{{ck.name}}</el-radio>
							<span v-if="pageParams[scope.current.keys]==3">
								备注：<el-input v-model="pageParams.siteStatusNotes"  placeholder="请输入" style='width: 160px !important;'></el-input>
							</span>
					</template>
					<template slot="uploadFile" slot-scope="scope" >
							<div class="labelTxt" >上传《租赁合同终止协议》</div>
							<el-upload ref="Yupload"
					      :action="upFiles.rootPath"
					      :file-list="upFiles.fileList"
					      :on-change="fileChange"
					      :on-error="uploadFail"
					      :with-credentials="true"
					      :auto-upload="false"
					    >
					     <el-button size="small" type="primary">点击上传</el-button>
					    </el-upload>
					</template>
				</TForm>
			</div>
			<div class="f_btns tx_right" >
					<el-button type="primary" class='w130' @click='$router.go(-1)'>取消</el-button>
					<el-button type="primary" class='w130' :disabled='disBtn' @click='onSubmit'>保存提交</el-button>
				</div>
		</div>
		<NotifyPerson v-if='notifyInfo.open' :isOpen='notifyInfo.open' :datas='notifyInfo.datas' @close='closeNotifyModel'  />
	</mainBox>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import NotifyPerson from './NotifyPerson.vue'
	export default {
		computed: {},
		components: {TForm,NotifyPerson},
		props: {},
		watch: {},
		data() {
			return {
				upFiles:{
  				typeArrs:[],
					isOpen:false,
					fileList:[],
					documentType: "",
  				rootPath: '/saascloud/saas/archive/param/archive/upload?ulType=DefaultUpload',
				},
				pageParams: {
					siteStatusNotes:'',
				}, //基础配置参数
			
				positionArrs:[],
			
				notifyInfo: { //选择联系人
					open: false,
					dataId: '',
					datas:{},
				},
				
				contractObjs:{},//退租合同信息
				contractForm:[
					{name: '租赁合同起止日期',keys: 'htStartEndTime',value: '',type: 'text',isMust:true,flex:12,},
					{name: '租赁单元',keys: 'positionName',value: '',type: 'text',isMust:true,flex:12,},
					{name: '租赁合同编号',keys: 'rentContractCode',value: '',type: 'text',isMust:true,flex:12,},
					{name: '物业合同编号',keys: 'propertyContractCode',value: '',type: 'text',isMust:true,flex:12,},
					{name: '计租面积（m2）',keys: 'rentArea',value: '',type: 'text',isMust:true,flex:12,},
					{name: '是否租用宿舍',keys: 'rentDormitoryStr',value: '',type: 'text',isMust:true,flex:12,},
					{name: '是否租用空调',keys: 'rentAirconditionStr',value: '',type: 'text',isMust:true,flex:12,},
				],
				formlist: [
					{name: '所属项目',keys: 'projectId',value: '',type: 'select',isMust:true,options:[],changMethod:this.changeProject,disabled:false},
					{name: '退租企业',keys: 'enterpriseId',value: '',type: 'select',isMust:true,options:[],changMethod:this.initHtMess,disabled:false},
					{name: '合同信息',keys: 'covenant',value: '',type: 'slot',slotName: 'contractInfo',labelHide:true,flex:14},
					{name: '退租日期',keys: 'rentEndingDate',value: '',type: 'time',isMust:true,format:'timestamp'},
					{name: '企业撤场联系人',keys: 'endingLiaison',value: '',type: 'input',isMust:true},
					{name: '联系人职位',keys: 'endingLiaisonPosition',value: '',type: 'input',isMust:true},
					{name: '联系人电话',keys: 'endingLiaisonPhone',value: '',type: 'input',isMust:true},
					{name: '撤场交付标准',keys: 'endingSiteStatus',value: '1',type: 'slot',slotName: 'deliver',labelHide:true,options:[
						{code:'1',name:'毛坯'},
						{code:'2',name:'现装'},
						{code:'3',name:'其他'},
					]},
					{name: '上传《租赁合同终止协议》',keys: 'zlhtFiles',value: [],type: 'upload2',diyClass:'diyUpfile'},
//					{name: '上传《租赁合同终止协议》',keys: 'enterpriseDocumentUploadVO',value: '',type: 'slot',slotName: 'uploadFile',labelHide:true,},
				],
				disBtn:false,
				rentEndingId:'',
			};
		},
		created() {
			const {rentEndingId}=this.$route.query;
			if(rentEndingId){
				this.rentEndingId=rentEndingId;
				this.formlist[0].disabled=true;
				this.formlist[1].disabled=true;
			}
			this.initNowHtml();
			this.$store.dispatch('getEmployeeDatas');//获取所有部门人员
		},
		mounted() {},
		methods: {
			uploadFail() {
				this.$message({ type: "error", message: this.$t("message.uploadFial") });
  		},
  		 async fileChange(myFile, fileArrs){
	    	this.upFiles.fileList = fileArrs;
	    	console.log('fileChange',fileArrs)
	    },
			async initNowHtml() {
				this.resetFormItVals();
				await this.initProjectArrs();
				this.getDetailById();
			},
			async	getDetailById(){
				
				if(!this.rentEndingId) return;
      	let params={
      		rentEndingId:this.rentEndingId,
      		isloading:false,
      	}
	     	let bkData = await this.ApiHttp('/organization/enterprise/rent/selectEndingOrderDetail',params);
      	if(bkData){
      		let res={...bkData.enterpriseRentOrderVO,...bkData.enterpriseRentEndingOrderVO};
    			console.log('222222',res)
    			const htXinx=bkData.enterpriseRentOrderVO;
    			htXinx.rentDormitoryStr=htXinx.rentDormitory=='Y'?'是':'否';//是否租用宿舍
    			htXinx.rentAirconditionStr=htXinx.rentAircondition=='Y'?'是':'否';//是否租用空调
    			htXinx.htStartEndTime=this.common.getTimeMmss(htXinx.leaseContractStartDate,'day')+' 至 '+this.common.getTimeMmss(htXinx.leaseContractEndDate,'day')
    			htXinx.rentArea=htXinx.rentArea+'';
    			let tzXinx=bkData.enterpriseRentEndingOrderVO;
    			tzXinx.endingSiteStatus=tzXinx.endingSiteStatus+'';
    			this.contractObjs={...htXinx};
    			this.pageParams={
    				...tzXinx,
    				rentEndingDate:new Date(tzXinx.rentEndingDate).getTime(),
    				projectId:htXinx.projectId,
    				zlhtFiles:[],
    			};
      	}
			},
			changeDelivetype(val){
				console.log('切换撤销交付标准',val)
				if(val!==3) this.pageParams.siteStatusNotes='';
			},
			changeProject(val,it){//项目切换
				console.log(val,it)
				this.pageParams.enterpriseId='';
				this.initEnterpriseArrs();
			},
			
			async initProjectArrs(){
				let res =await this.$store.dispatch('getAllProjects');
				if(res){
  				res.map(it=>{
						it.name = it.projectName;
						it.code = it.projectId;
					})
					this.common.insertOptions(this.formlist,'projectId',res);
				}
			},
			async initHtMess(){
				//查询合同信息
				let params={
					enterpriseId:this.pageParams.enterpriseId,
				}
				let toUrl='/organization/enterprise/rent/selectByEnterpriseId';
				let res = await this.ApiHttp(toUrl,params);
				console.log('ssss',res)
				if(res){
					if(res.rentOrderId){
						res.rentArea=res.rentArea+'';
						res.htStartEndTime=this.common.getTimeMmss(res.leaseContractStartDate,'day')+' 至 '+this.common.getTimeMmss(res.leaseContractEndDate,'day')
						res.rentDormitoryStr=res.rentDormitory=='Y'?'是':'否';
						res.rentAirconditionStr=res.rentAircondition=='Y'?'是':'否';
						this.contractObjs={...res};
						this.disBtn=false;
					}else{
						this.$message('该企业下无入住订单');
						this.resetHtInfos();
						this.disBtn=true;
					}
				}
				
			},
			async initEnterpriseArrs() {
	      //获取企业列表
	      let params = {
	        projectId: this.pageParams.projectId,
	      };
	      let res = await this.ApiHttp("/organization/enterprise/list", params);
	      if (res) {
	        res.map((it) => {
//	          if (it.enterpriseName.length > 20) {
//	            it.enterpriseName = it.enterpriseName.slice(0, 20) + "...";
//	          }
	          it.name = it.enterpriseName;
	          it.code = it.enterpriseId;
	        });
	      }
	      this.common.insertOptions(this.formlist,'enterpriseId',res || []);//添加选项数据到所属项目
	    },
			async initPositionTree(projectId){
				let res=await this.ApiHttp('/base/tenant/position/selectPositionTree?projectId='+projectId);
				if(res){
					res.map(it=>{
						it.name = it.positionName;
						it.code = it.positionId;
					})
					this.positionArrs=res;
//					this.common.insertOptions(this.formlist,'positionId',res);//添加选项数据到所属项目
				}
			},
	
			closeNotifyModel(isInit) {
				this.notifyInfo.open=false;
				if(isInit){
					this.$router.go(-1)
				}
			},
			resetFormItVals() {
				//重置表单的值  父组件有调用
				for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.pageParams, item.keys, item.value);
				}
				this.resetHtInfos();
			},
			resetHtInfos(){
				for(let item of this.contractForm) { //初始化赋值我的表单数据
					this.$set(this.contractObjs, item.keys, item.value);
				}
			},
			async onSubmit() {
				if(this.pageParams.zlhtFiles.length<1){
					this.$message('请选择终止协议文件');
					return;
				}
				let params = {
					...this.pageParams,
					rentOrderId:this.contractObjs.rentOrderId,
					enterpriseDocumentUploadVO:{
						documentType:'contract_type',
						enterpriseId:this.pageParams.enterpriseId,
						files:this.pageParams.zlhtFiles,
					}
				}
				delete params.zlhtFiles;
				this.notifyInfo.datas=JSON.parse(JSON.stringify(params));
				this.notifyInfo.open=true;
				console.log('89898',JSON.parse(JSON.stringify(params)))
			},
			
		},
		destroyed() {},
	};
</script>

<style lang="scss" scoped>
	.addHtml{
		height: 100%;
		.infoFrom{
			/*height: 100%;*/
			height: calc(100% - 50px);
			overflow-y: auto;
			.border1px{
				margin-bottom: 32px;
				padding: 24px;
				padding-bottom: 4px;
				border: 1px solid #DCDFE5;
				position: relative;
			}
			.part_cost{
				text-align: right;
				position: absolute;
				right: 24px;
				bottom: 36px;
			}
			.allCost{
				color: #000000;
				font-weight: bold;
				font-size: 16px;
			}
		}
		.labelTxt{
			font-size: 14px;color: #606266;font-weight:700
		}
	}
	
	
</style>
<style lang="scss">
	.fqBox.mainBox .m-body{
		padding: 24px !important;
	}
	.basisBox{
		.el-input,.el-select{
			width: 350px !important;
		}
		.soltSpan{
			display: inline-block;
			padding-left: 12px;
			width: 150px;
	    line-height: 16px;
	    vertical-align: bottom;
	    color: #303133;
		}
	}
	.costWarp{
		.el-input{
			width: 88% !important;
		}
		
	}
	.Y-myFrom .showlable{
		padding:12px 18px;
		background: #F8F8F8;
		.el-form-item{
			margin-bottom: 6px !important;
		}
		.el-form-item__label{
			display:block !important;
			text-align: right;
			font-weight: 400;
			color: #909399;
		}
	} 
	.diyUpfile{
		.el-form-item__label{
			width: 100% !important;
		}
		.el-form-item__content{
			margin-left: 0 !important;
			#zlhtFiles{
				width: 50%;
			}
		}
		
	}
</style>