<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' title="选择通知人员" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="30%" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'></TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 确定</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			datas:{
				type: Object,
				default:()=>{
					return {}
				}
				
			},
		},
		components: {TForm},
		data() {
			return {
				htmlDatas:{
					
  			},
  			isHttping:false,
  			formlist:[
  				{name: '选择招商部审核人',keys: 'discuss',isKey:true,value: '',type: 'employe',isMust:true,flex:13},
  				{name: '通知方式',keys: 'discussValue',value: '1',type: 'select',flex:11,options:[{name:'发送短信通知',code:'1'}]},
  				{name: '选择客服部审核人',keys: 'kefu',isKey:true,value: '',type: 'employe',isMust:true,flex:13},
  				{name: '通知方式',keys: 'kefuValue',value: '1',type: 'select',flex:11,options:[{name:'发送短信通知',code:'1'}]},
  			],
			}
		},
		
		computed: {},
		watch:{},
		created() {
			this.resetTFrom();
		},
		mounted() {
			this.$nextTick(()=>{
			})
			
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
		    changeCompanyName(val) {
		    	// 联动企业名称  生成编码
		      let n = pinyin.getCamelChars(val);
		      this.htmlDatas.materielCategoryCode=n;
		    },
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let Arrs=[];
				let sort=0;
				for(let it of this.formlist){
					if(it.isKey){
				   	let v=it.keys+'Value';
				   	sort++;
				   	let itObj={
	            "userId": this.htmlDatas[it.keys],
	            "examineSequence": sort,
	            "notificationMethod": this.htmlDatas[v],
	        	}
				   	Arrs.push(itObj)
					}
				}
				if(this.htmlDatas.discuss==this.htmlDatas.kefu){
					this.$message('通知人员不能选择同一个人');
					return
				}
				let params={
					...this.datas,
					enterpriseRentExamineVOS:Arrs,
				}
				let toUrl='/organization/enterprise/rent/createEndingOrder';
				if(this.datas.rentEndingId){
					toUrl='/organization/enterprise/rent/reEndingOrder';
				}
				let res = await this.ApiHttp(toUrl,params,'post');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>